import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import Glide, { Options, Properties, Static } from '@glidejs/glide'
import PubSub, { PubSubStatic } from '../../../utils/class/PubSub'
import {
  getActiveIndex,
  getCurrentBreakpointOptions,
  getNavStatus
} from '../../../utils/functions/glideSlider'
import cx from 'classnames'

import { GlideNavArrows } from './navigations/arrows'
import { GlideNavBullets } from './navigations/bullets'

import styles from './glideSlider.module.scss'

/**
 * GlideSlider Component.
 * @type {Reusable Component}
 * @param {Component} children @required props glide slide item.
 * @param {Object} glideOptions @optional props for additional glide options and settings.
 * @param {function} beforeMountCallBack  @optional returns {Object} unmounted glide instance. Callback hooking into glide instance before mount
 * @param {function} afterMountCallBack @optional returns {Object} mounted glide instance. Callback hooking into glide instance after mount
 *
 * @returns {JSX.Element}
 */
const GlideSlider: FunctionComponent<GlideProps> = (props) => {
  const { children = [], glideOptions, beforeMountCallBack, afterMountCallBack, classNames } = props

  const sliderRef = useRef<any>(null)
  const pubSub: PubSubStatic = useMemo(() => new PubSub().export(), [])
  const glideIdNav: string = useMemo<string>(
    () => (Math.random() * 1e7 + '').replace('.', '').toString(),
    []
  )
  const glideIdBullets: string = useMemo<string>(
    () => (Math.random() * 1e7 + '').replace('.', '').toString(),
    []
  )
  const [glideInstance, setGlideInstance] = useState<{
    isMounted: boolean
    mountedGlide: MountedGlide | null
  }>({
    isMounted: false,
    mountedGlide: null
  })

  const perView = glideOptions?.perView ?? 1
  const status: Status = {
    navArrows: {
      prev: false,
      next: perView < children.length
    },
    bullets: {
      activeIndex: 0,
      slidesCount: children?.length ?? 0,
      perView: glideInstance.isMounted
        ? getCurrentBreakpointOptions(glideOptions?.breakpoints ?? {})?.perView ??
          glideOptions?.perView ??
          1
        : 1
    },
    showDots: glideOptions?.showDots ?? false
  }

  const subscriberNav: GlideSubscriber = {
    subscribe: pubSub.subscribe,
    eventId: glideIdNav
  }

  const subscriberBullets: GlideSubscriber = {
    subscribe: pubSub.subscribe,
    eventId: glideIdBullets
  }

  useEffect(() => {
    if (!sliderRef || !children.length || glideInstance.isMounted) return
    const glide: GlideStatic = new Glide(sliderRef?.current ?? '', {
      ...glideOptions
    })

    beforeMountCallBack && beforeMountCallBack(glide)

    const syncNavBulletsOnEvent = (): void => {
      const slidesCount = glide._c?.Html?.slides?.length ?? children.length
      let perView: number = glideOptions?.perView ?? 1
      perView = getCurrentBreakpointOptions(glideOptions?.breakpoints ?? {})?.perView ?? perView

      if (slidesCount > perView) {
        const currentSlideIndex: number = glide?.index ?? 0
        const { next, prev }: { next: boolean; prev: boolean } = getNavStatus(
          slidesCount,
          perView,
          currentSlideIndex
        )

        pubSub.publish(glideIdNav, {
          navArrows: { next, prev }
        })

        pubSub.publish(glideIdBullets, {
          bullets: {
            activeIndex: getActiveIndex({ perView: perView, currentSlide: currentSlideIndex }),
            slidesCount,
            perView
          }
        })
      }
    }

    glide.on('build.after', () => {
      syncNavBulletsOnEvent()
    })

    glide.on('update', () => {
      syncNavBulletsOnEvent()
    })

    // called only onces after transition
    glide.on('run.after', () => {
      syncNavBulletsOnEvent()
    })

    const mountedGlide: MountedGlide = glide.mount()
    if (!glideInstance.isMounted) {
      setGlideInstance({ isMounted: true, mountedGlide })
    }

    afterMountCallBack && afterMountCallBack(mountedGlide)

    // return () => {
    //   mountedGlide.destroy()
    // }
  }, [
    afterMountCallBack,
    beforeMountCallBack,
    children.length,
    glideOptions,
    glideInstance,
    pubSub,
    glideIdNav,
    glideIdBullets
  ])

  useEffect(() => {
    if (glideInstance.isMounted) {
      if (glideInstance.mountedGlide?._c?.Controls?.mount) {
        glideInstance.mountedGlide?.update({}) // update slides and settings but doesn't update controls by default see next line
        glideInstance.mountedGlide?._c?.Controls?.mount() // update controls too
      }
    }
  }, [children, glideInstance.mountedGlide, glideInstance.isMounted])

  if (!children.length) return null

  return (
    <div className={cx(styles['glide'], classNames?.mainWrapper ?? '')} ref={sliderRef}>
      <div className={styles['glide__track']} data-glide-el='track'>
        <div
          className={cx([styles['glide__slides'], classNames?.slidesWrapper], {
            [styles['glide__slides--not-mounted']]: !glideInstance.isMounted
          })}
        >
          {children}
        </div>
      </div>
      {glideOptions?.showNavArrow && children.length > 1 && (
        <GlideNavArrows
          classNames={classNames?.navigation?.arrows}
          status={status}
          subscriber={subscriberNav}
          glideOptions={glideOptions}
          mountedGlide={glideInstance.mountedGlide}
        />
      )}
      {status.showDots && children.length > 1 && (
        <GlideNavBullets
          status={status}
          subscriber={subscriberBullets}
          glideOptions={glideOptions}
        />
      )}
    </div>
  )
}

export type Status = {
  navArrows: {
    prev: boolean
    next: boolean
  }
  bullets: {
    activeIndex: number
    slidesCount: number
    perView: number
  }
  showDots: boolean
}

export type GlideSubscriber = {
  subscribe: (channel: string, message: (result: Status) => any) => void
  eventId: string
}
export interface GlideOptions extends Options {
  showNavArrow?: boolean
  showDots?: boolean
  ignorePerViewInControls?: boolean
}

export interface GlideInstance extends MountedGlide, Properties {}

export interface GlideStatic extends Static {
  /* stores currenly displayed slider index position
   * useful during glide events to get the current slides
   */
  index?: number
  _c?: {
    Html: {
      slides: {
        length: number
      }
    }
  }
}

type GlideProperty = {
  mount?: () => void
  [key: string]: any
}
export interface MountedGlide extends Properties {
  _c?: {
    Html?: GlideProperty
    Controls?: GlideProperty
    Keyboard?: GlideProperty
    Build?: GlideProperty
  }
  //active index
  _i?: number
}

export type GlideProps = {
  classNames?: {
    mainWrapper?: string
    slidesWrapper?: string
    navigation?: {
      arrows?: {
        wrapper?: string
        prev?: string
        next?: string
      }
    }
  }
  children: ReactNode[]
  glideOptions?: GlideOptions
  beforeMountCallBack?: (glide: GlideStatic) => void
  afterMountCallBack?: (mountedGlide: Properties) => void
}

export default GlideSlider
