import { JSX } from 'react'
import { components, GroupBase, InputProps, OptionProps } from 'react-select'
/**
 * @description
 * react-select has issue of generating different attributes for components in ssr and client side.
 * this object contains amended components which resolved server/client side unmatched attribute issue.
 * @usage
 * include in components prop in Select from react-select
 * eg. components:{Input:amendedComponents.Input, ...otherCustomComponents}
 * it is not recommended to use components:{...amendedComponents, ...otherCustomComponents} due to the followings:
 * - only certain select default components are being used in select depending on other props, therefore always override specifically.
 * - the content of amendedComponents may also change.
 */
export const amendedComponents = {
  /**
   * often you might need to pass in more props:
   * - for better performance, make sure the component is not declared in the scope of the Select's render method
or it will rerender the input every time state is changed
   * - useful props:
   *   - placeholder: placeholder attached to input, it is not the same as the placeholder defined in Select,
   *     set parent Select placeholder to '' to remove Select default placeholder and avoid conflicts.
   *   - isHidden: false prevents Select from hiding input after setting value
   *   - controlShouldRenderValue: should be set to false to avoid conflicts if isHidden is always set to true,
   * @returns
   */
  Input: (props: AmendedInputProps) => {
    //remove aria-activedescendant to match client and server side
    return <components.Input {...props} aria-activedescendant={undefined} />
  },
  Option: (props: AmendedOptionProps) => {
    // this is to resolve hydration errors due to extra properties being shown in server side
    delete props.innerProps?.['aria-selected']
    delete props.innerProps?.['aria-activedescendant']
    return <components.Option {...props} />
  }
}

export type AmendedInputProps = JSX.IntrinsicAttributes &
  InputProps<any, boolean, GroupBase<any>> & { 'data-testid'?: string }

export type AmendedOptionProps = JSX.IntrinsicAttributes &
  OptionProps<any, boolean, GroupBase<any>> & { 'data-testid'?: string }
