/**
 * External dependencies
 */
import isEmpty from 'lodash/isEmpty'
import { getVariantNameUsingTailpipe } from '../../../utils/functions/showrooms'

/**
 * Function to create formatted rating data for multi car.
 *
 * @param {ratingData} ratingData Raw rating data.
 */
export const getMultiCarRatingData = (vehicles = []) => {
  const ratingData = !isEmpty(vehicles) ? vehicles?.filter((v) => !isEmpty(v?.ratings)) : []

  // Return if no data found.
  if (isEmpty(ratingData) || ratingData.length >= 10) {
    return {
      overallRatingData: [],
      ratingBreakdown: []
    }
  }

  const overallRatingData = []

  let highestRate = 0 // To save highest rate
  let lowestRateIndex = 0 // To save lowest rate index of array
  // Loop to create overall rating data.
  ratingData.map((car) => {
    const currentRate = car?.ratings?.overall

    if (highestRate === 0) {
      highestRate = currentRate
    }
    const variantName = getVariantNameUsingTailpipe(car?.variant)

    const rateData = {
      name: variantName,
      rate: currentRate && currentRate.toFixed(1),
      winner: 0,
      car // Adding this in object just to get data for rating breakdown component.
    }

    // If rate is highest then make add it in first place.
    if (Math.round(highestRate * 100) < Math.round(currentRate * 100)) {
      highestRate = currentRate
      lowestRateIndex++
      overallRatingData.unshift(rateData)
    } else if (
      !isEmpty(overallRatingData) &&
      Math.round(overallRatingData[lowestRateIndex].rate * 100) < Math.round(currentRate * 100)
    ) {
      // Add element in between of array
      overallRatingData.splice(lowestRateIndex, 0, rateData)
      lowestRateIndex++
    } else {
      // Add element at last of the array
      if (!isEmpty(overallRatingData)) {
        lowestRateIndex++
      }
      overallRatingData.push(rateData)
    }
    return overallRatingData
  })

  overallRatingData[0].winner = 1

  const ratingBreakdownData = {
    performance: [],
    ride: [],
    handling: [],
    technology: [],
    interior: [],
    safety: [],
    infotainment: [],
    efficiency: [],
    value: [],
    fitness: []
  }

  overallRatingData.forEach((row, index) => {
    const variantName = getVariantNameUsingTailpipe(row?.car?.variant)
    // Loop on the all cars to set rating data
    for (const ratingProperty in row?.car?.ratings) {
      if (Object.prototype.hasOwnProperty.call(ratingBreakdownData, ratingProperty)) {
        ratingBreakdownData[ratingProperty].push({
          name: variantName,
          rate: row?.car?.ratings[ratingProperty],
          winner: 0
        })
      }
    }

    delete row.car // Remove object from all array elements
  })

  let ratingBreakdownDetails = []

  if (ratingData.length > 4) {
    // Rating breakdown details for multi car review
    ratingBreakdownDetails = [
      { category: 'Performance', carRating: ratingBreakdownData?.performance },
      { category: 'Ride Quality', carRating: ratingBreakdownData?.ride },
      { category: 'Driver Technology', carRating: ratingBreakdownData?.technology },
      { category: 'Interior Comfort & Packaging', carRating: ratingBreakdownData?.interior },
      { category: 'Value', carRating: ratingBreakdownData?.value }
    ]
  } else {
    // Rating breakdown details for multi car review
    ratingBreakdownDetails = [
      { category: 'Performance', carRating: ratingBreakdownData?.performance },
      { category: 'Ride Quality', carRating: ratingBreakdownData?.ride },
      { category: 'Handling & Dynamics', carRating: ratingBreakdownData?.handling },
      { category: 'Driver Technology', carRating: ratingBreakdownData?.technology },
      { category: 'Interior Comfort & Packaging', carRating: ratingBreakdownData?.interior },
      { category: 'Safety Technology', carRating: ratingBreakdownData?.safety },
      { category: 'Infotainment & Connectivity', carRating: ratingBreakdownData?.infotainment },
      { category: 'Energy Efficiency', carRating: ratingBreakdownData?.efficiency },
      { category: 'Value for Money', carRating: ratingBreakdownData?.value },
      { category: 'Fit for Purpose', carRating: ratingBreakdownData?.fitness }
    ]
  }

  // Loop on the Rating breakdown data to set the winner for particular category
  ratingBreakdownDetails.forEach((category) => {
    let highestRateIndex = 0
    let highestCategoryRate = 0

    // Loop on each car for the single category
    // and decide the winner for the category.
    category.carRating.forEach((car, catIndex) => {
      const currentRate = car?.rate

      if (!highestCategoryRate) {
        highestCategoryRate = currentRate
      }

      // If current rate is higher than the highest rate then update index and highest value
      if (Math.round(highestCategoryRate * 100) < Math.round(currentRate * 100)) {
        highestCategoryRate = currentRate
        highestRateIndex = catIndex
      }
    })

    category.carRating[highestRateIndex].winner = 1
  })

  return {
    overallRatingData,
    ratingBreakdown: ratingBreakdownDetails
  }
}
