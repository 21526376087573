/* eslint-disable max-lines */
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

import { getImageData } from '../seo'
import { formatPrice, mergeObjectArray, priceFormat, sanitize } from './miscellaneous'
import {
  CARS_FOR_SALE_ARCHIVES_PAGE,
  CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE,
  CARS_FOR_SALE_LISTINGS_CARD
} from '../../constants/pages'
import { sortOptions, STATE_NAME_MAP } from '../../constants/cars-for-sale'
import {
  generateCFSSearchFacadeUrl,
  generateCFSUrl
} from '@grille/components/cars-for-sale/listings/helper'
import { DEFAULT_CFS_FILTERS } from '@grille/components/cars-for-sale/listings/constants'
import cloneDeep from 'lodash/cloneDeep'
import { getRuntimeEnv } from './get-runtime-env'

/** Image filter queries */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const imageSortQuery = {
  order: [['imageAndRecency', 'DESC']]
}

const recommendedSortQuery = {
  order: [['recommended', 'DESC']]
}

const listingTypeLabel = (listingType = '') => {
  const type = listingType.toLowerCase()
  switch (type) {
    case 'used':
      return 'Used'
    case 'new':
      return 'New'
    case 'demo':
      return 'Demo & Near New'
    default:
      return 'All'
  }
}

const getListingTypeHeading = (selectedListingTypes = []) => {
  let listingTypeHeading = 'All'

  if (selectedListingTypes?.length === 1) {
    listingTypeHeading = `${listingTypeLabel(selectedListingTypes[0])}`
  }
  if (selectedListingTypes?.length > 1) {
    listingTypeHeading = `${selectedListingTypes.map(listingTypeLabel).join(', ')}`
  }

  return listingTypeHeading
}

const getCFSDefaultLabel = (count = 0) => {
  if (count === 1) {
    return 'Car For Sale'
  }
  return 'Cars For Sale'
}

const getPriceHeading = (
  min = 0,
  max = 0,
  config = {
    labelForUnder: 'Under',
    labelForOver: 'Over'
  }
) => {
  if (min && max) {
    return `${min}-${max}`
  }
  if (min) {
    return `${config.labelForOver} ${min}`
  }
  if (max) {
    return `${config.labelForUnder} ${max}`
  }
  return ''
}

// Generate heading for cfs pages
export const getDefaultCustomHeading = (config) => {
  const {
    count = 0,
    listingTypes = [],
    state: rawState = '',
    region: rawRegion = '',
    make: rawMake = '',
    model: rawModel = '',
    priceRange = [],
    fuelTypes = [],
    bodyTypes = []
  } = config ?? {}
  const state = rawState && rawState !== 'all' ? startCase(rawState) : ''
  const updatedRegion = startCase(rawRegion)
  const region = rawRegion && rawRegion !== 'all' ? startCase(updatedRegion) : ''
  const make = rawMake
  const model = rawModel
  const hasAnyFilter =
    !isEmpty(make) ||
    !isEmpty(model) ||
    !isEmpty(state) ||
    !isEmpty(region) ||
    !isEmpty(priceRange) ||
    !isEmpty(fuelTypes) ||
    !isEmpty(bodyTypes)
  let listingType = getListingTypeHeading(listingTypes)
  let title = []

  let listingCountLabel = ''

  // Only add counts if there are any filters applied
  if (count && count > 0 && hasAnyFilter) {
    listingCountLabel = `${priceFormat(count)}`
  }
  title.push(listingCountLabel)

  let makeModelLabel = ``
  if (make) {
    makeModelLabel = `${make}`
    if (model) {
      makeModelLabel = `${makeModelLabel} ${model}`
    }
  }
  title.push(makeModelLabel)

  let fuelTypeLabel = ''
  if (!isEmpty(fuelTypes)) {
    if (fuelTypeLabel.length === 1) {
      fuelTypeLabel = `${fuelTypes[0].label} `
    } else {
      fuelTypeLabel = `${fuelTypes.map((f) => f.label).join(', ')} `
    }
  }
  title.push(fuelTypeLabel)

  // Add Listing Type
  if (hasAnyFilter && listingType === 'All') {
    listingType = ''
  }
  title.push(listingType)

  let bodyTypeLabel = ''
  if (!isEmpty(bodyTypes)) {
    if (bodyTypeLabel.length === 1) {
      bodyTypeLabel = `${bodyTypes[0].label} `
    } else {
      bodyTypeLabel = `${bodyTypes.map((f) => f.label).join(', ')} `
    }
  }

  title.push(bodyTypeLabel)
  title.push(getCFSDefaultLabel(count))

  let priceLabel = ''
  if (priceRange && priceRange.length > 0) {
    const min = priceRange?.[0] ? formatPrice(priceRange?.[0], true) : null
    const max = priceRange?.[1] ? formatPrice(priceRange?.[1], true) : null
    priceLabel = getPriceHeading(min, max)
  }
  title.push(priceLabel)
  let locationLabel = ''
  if (state) {
    if (region) {
      locationLabel = `${region}, `
    }
    locationLabel = `in ${locationLabel}${state}`
  }
  title.push(locationLabel)

  return title.filter((t) => t.length > 0).join(' ')
}

export const getDefaultCustomTitle = (
  defaultTitle,
  state = '',
  region = '',
  make = '',
  model = '',
  selectedListingType = []
) => {
  let listingType = 'All '
  if (selectedListingType) {
    if (selectedListingType.includes('used')) {
      listingType = 'Used '
    }
    if (selectedListingType.includes('new')) {
      listingType = 'New '
    }
    if (selectedListingType.includes('demo')) {
      listingType = 'Demo '
    }
    if (
      selectedListingType.includes('new') &&
      selectedListingType.includes('used') &&
      selectedListingType.includes('demo')
    ) {
      listingType = 'All '
    }
  }

  const defaultPageTitle = !isEmpty(defaultTitle) ? sanitize(defaultTitle) : 'Cars For Sale'
  const parsedState = state && state !== 'all' ? startCase(state) : ''
  const updatedRegion = startCase(region)
  const parsedRegion = region && region !== 'all' ? startCase(updatedRegion) : ''
  const parseMake = make
  const parsedModel = model

  let title = ''

  if (isEmpty(parseMake) && isEmpty(parsedModel)) {
    title += `${defaultPageTitle} `
  }

  if (!isEmpty(parseMake)) {
    title += parsedModel
      ? `${parseMake} ${parsedModel} Cars For Sale `
      : `${parseMake} Cars For Sale `
  }

  if (!isEmpty(parsedRegion)) {
    title += `in ${parsedRegion}`
  }

  if (!isEmpty(parsedState)) {
    title += parsedRegion ? `, ${parsedState}` : `in ${parsedState}`
  }

  return `${listingType}${title}`
}

/**
 * extracts vehicleKeys from listing data
 * @param {Array} results DealerListing results
 */
export const getVehicleKeys = (results = []) => {
  return !isEmpty(results) ? results.map((listing) => listing?.vehicleKey) : []
}

/**
 * Create URL path parms using query update and router
 * eg. /all/all/[make]/[model]
 */
export const createUrlPathParams = (state, region, make, model) => {
  let urlState
  let urlRegion
  let urlMake
  let urlModel

  /** If have state  */
  if (state) {
    /** If have state and make  */
    if (make) {
      urlRegion = region || 'all'

      /** Set make only if it not 'all'   */
      urlMake = make && make !== 'all' ? make : ''
      /** Set model only if  we have make and its not 'all' */
      urlModel = make && model && model !== 'all' ? model : ''
    } else {
      /** Set region only if we have region & state which are not 'all'  */
      urlRegion = region && region !== 'all' && state !== 'all' ? region : ''
    }
    /** Set state if we have region or state   */
    urlState = urlRegion || state !== 'all' ? state : ''
  } else {
    /** If only have make  */
    if (make) {
      /** Set region, state to all eg. /all/all/[make] */
      urlState = 'all'
      urlRegion = 'all'

      /** Set make if its not 'all' */
      urlMake = make && make !== 'all' ? make : ''
      /** Set model if we have make and model is not 'all' */
      urlModel = make && model && model !== 'all' ? model : ''
    }
  }
  return {
    state: urlState,
    region: urlRegion,
    make: urlMake,
    model: urlModel
  }
}

/**
 * Returns Query object for sort query using its id
 * @param {String} id Sort Quer Hash Fragment id
 * @returns {Object} Sort Query Object eg. { order : [["created_at": "ASC"]] }
 */
const getSortQuery = (id) => {
  if (!id) {
    return null
  }
  const sortObject = sortOptions.find((option) => option?.id === id)
  return sortObject || null
}

/** Get default query for sortBy */
export const getDefaultSortQuery = () => {
  const sortQuery = getSortQuery('featured')?.query ?? null
  return addDefaultSortQueries(sortQuery?.query)
}

/**
 * Creates Where, Sort query from URL queries and filter if provided
 * @returns Strgingified query
 */
export const createListingQueries = (router, filter) => {
  let whereQuery = {}
  let sortQuery = {}
  /** Router query is not updating correctly, gives stale query parmas */
  const routerQuery = router?.query || {}
  /** Take filters value or take current values from URL */
  const make = filter?.make ?? routerQuery?.make
  const model = filter?.model ?? routerQuery?.model
  const state = filter?.state ?? routerQuery?.state
  const region = filter?.region ?? routerQuery?.region

  const urlQuery = getURLSearchParams()

  if (urlQuery) {
    if (state && state !== 'all') {
      urlQuery.set('state', state)
    }

    if (region && region !== 'all') {
      urlQuery.set('region', region)
    }

    if (make && make !== 'all') {
      urlQuery.set('make', make)
    }

    if (model && model !== 'all') {
      urlQuery.set('model', model)
    }
    /** Add default sort if sort filter is skiped */
    const hasSortFilter = getURLQueryValue('sort')
    if (!hasSortFilter) {
      urlQuery.set('sort', 'featured')
    }
    urlQuery.forEach((value, field) => {
      const fieldQuery = getFieldQuery(field, value)
      if (fieldQuery?.whereQuery) {
        whereQuery = { ...whereQuery, ...fieldQuery?.whereQuery }
      }
      if (fieldQuery?.sortQuery) {
        sortQuery = { ...sortQuery, ...fieldQuery?.sortQuery }
      }
    })
  } else {
    const filterQueryObj = { make, model, state, region }
    const queryKeys = Object.keys(filterQueryObj) || []
    queryKeys.forEach((key) => {
      const value = filterQueryObj?.[key]

      if (value && value !== 'all') {
        const fieldQuery = getFieldQuery(key, value)
        if (fieldQuery?.whereQuery) {
          whereQuery = { ...whereQuery, ...fieldQuery?.whereQuery }
        }
        if (fieldQuery?.sortQuery) {
          sortQuery = { ...sortQuery, ...fieldQuery?.sortQuery }
        }
      }
    })
    /** Adds default sort query */
    sortQuery = getFieldQuery('sort', 'featured')?.sortQuery
  }
  /** Adds queries for sorting by listings with more than 1 image */
  whereQuery = addDefaultFilterQueries(whereQuery)
  sortQuery = addDefaultSortQueries(sortQuery?.order)

  return { whereQuery, sortQuery }
}

/**
 * Adds default filter queries
 * - Filters listing with 0 images
 * @param {Object} whereQuery Where query object
 * @returns {Object} Query object
 */
export const addDefaultFilterQueries = (whereQuery) => {
  let query = {}
  if (!isEmpty(whereQuery)) {
    query = { ...query, ...whereQuery }
  }
  return query
}

/**
 * Adds default sort queries
 * - Sorts listing by no of images
 * @param {Object} sortQuery Sort Query Object
 * @returns {Object} Query object
 */
export const addDefaultSortQueries = (sortQuery) => {
  let query = { order: [...recommendedSortQuery?.order] }
  if (!isEmpty(sortQuery) && isArray(sortQuery?.order) && !isEmpty(sortQuery?.order)) {
    query = { order: [...sortQuery?.order, ...query?.order] }
  }
  return query
}

/**
 * Parse URL query value
 * @param {String} value String Hash query value
 * @returns {Any} Parsed hash query value
 * eg. "[used,new]"  => [ "used", "new" ]
 */
export const parseURLQueryValue = (value) => {
  if (!value || !value.length) {
    return null
  }
  let updatedValue = value
  /**
   * Convert string array to array
   * eg "[new,used]"" => [ "new", "used" ]
   */
  if (value[0] === '[' && value[value.length - 1] === ']') {
    updatedValue = value.slice(1, value.length - 1).split(',')
  } else {
    /** Convert slug to normal string */
    updatedValue = updatedValue?.replace(/-/g, ' ')
  }
  return updatedValue
}

/**
 * Get URL query value for given key
 * @param {String} key Key
 * @returns {any} Returns parsed value for given key
 */
export const getURLQueryValue = (key) => {
  if (!key) {
    return null
  }
  const queryObj = getURLSearchParams()
  if (queryObj) {
    const queryValue = queryObj.get(key)
    if (queryValue) {
      return parseURLQueryValue(queryValue)
    }
  }
  return null
}

/**
 * Returns URL query  without "#"
 * @returns {String} URL query
 */
export const getURLQueryString = () => {
  const url = window?.location?.hash || ''
  const hashQueryIndex = url.search('#')
  /** Has url query  */
  if (hashQueryIndex !== -1) {
    return url.slice(hashQueryIndex + 1, url.length)
  }
  return null
}

/**
 * Get filter query object from URL query
 */
export const getFilterQueryObjectFromURL = () => {
  let filterQueryObject = {}
  const queryObj = getURLSearchParams()
  if (queryObj) {
    queryObj.forEach((value, key) => {
      const parsedValue = value
      if (parsedValue) {
        filterQueryObject = {
          ...filterQueryObject,
          [key]: { value: parsedValue, isSelected: true }
        }
      }
    })
  }
  return filterQueryObject
}

/** Creates NextJS Path from available filters
 * eg. '/cars-for-sale/search/[state]/[region]'
 */
export const createBaseURLWithURLParams = (urlParams) => {
  let url = '/cars-for-sale/search/'
  if (urlParams?.state) {
    url += `${urlParams?.state}/`
    if (urlParams?.region) {
      url += `${urlParams?.region}/`
      if (urlParams?.make) {
        url += `${urlParams?.make}/`
        if (urlParams?.model) {
          url += `${urlParams?.model}/`
        }
      }
    }
  }
  return url
}

/** Update URL with hash query string */
export const updateURL = (router, hash, urlParams, options = { hashOnly: false }) => {
  if (!isEmpty(hash) && options.hashOnly) {
    if (typeof window !== 'undefined') {
      window.location.hash = hash
      return
    }
  }

  if (!router) {
    return null
  }

  window.location.href = createBaseURLWithURLParams(urlParams) + hash
}

/**
 * Create query for filter query from URL
 * @param {String} fieldName
 * @param {any} fieldValue
 * @returns {object} filter query for URL query  filter
 * eg. { stockType : [new] }
 */
const getFieldQuery = (fieldName, fieldValue) => {
  if (!fieldName) {
    return null
  }
  const parsedValue = parseURLQueryValue(fieldValue)

  if (isEmpty(parsedValue)) {
    return null
  }
  switch (fieldName) {
    case 'listingType':
      return isValidListingType(parsedValue) ? { whereQuery: { stockType: parsedValue } } : null
    case 'sort':
      /** Get Sort query from sort id  */
      // eslint-disable-next-line
      const { query } = getSortQuery(fieldValue) || {}
      return { sortQuery: query }
    case 'price':
      return {
        whereQuery: {
          and: [
            { priceIgc: { between: parsedValue.map((v) => parseInt(v)) } },
            { priceEgc: { between: parsedValue.map((v) => parseInt(v)) } }
          ]
        }
      }
    case 'year':
      return {
        whereQuery: {
          year: { between: parsedValue.map((v) => parseInt(v)) }
        }
      }
    case 'kms':
      return {
        whereQuery: {
          odometer: { between: parsedValue.map((v) => parseInt(v)) }
        }
      }
    case 'bodyType':
      return { whereQuery: { bodyType: parsedValue } }
    case 'make':
      return { whereQuery: { makeSlug: parsedValue } }
    case 'model':
      return { whereQuery: { modelSlug: parsedValue } }

    case 'state':
      return { whereQuery: { state: parsedValue } }
    case 'region':
      // eslint-disable-next-line
      const regionId = getRegionId(fieldValue)
      return { whereQuery: { regionId: regionId } }
    default:
      return null
  }
}

/**
 * Returns URLSearchParams Object from URL hash fragment.
 * Returns Null if no hash fragment found
 * @returns URLSearchParams Object || null
 */
const getURLSearchParams = () => {
  const queryString = getURLQueryString()
  if (queryString) {
    return new URLSearchParams(queryString)
  }
  return null
}

/** Checks if URL has hash query eg. #sort=featured.
 * Ignores make, model, state, region queries eg. "/state/region/make/model/"
 * @returns {Boolean} Returns true if has query is present
 */
export const hasHashQuery = () => {
  const hasURLParms = getURLSearchParams()
  return Boolean(hasURLParms)
}

/**
 * Checks if Valid Listing Type Value
 * @param {Array} listingType Array of Listing type values eg. ["new", "used"]
 * @returns {boolean} True if Valid Listing Type
 */
const isValidListingType = (listingType) => {
  if (isArray(listingType)) {
    return listingType.includes('new') || listingType.includes('used')
  }
  return null
}

// Splitting the region slug to extract id ( e.g. extract 1 from 'region-1' )
export const getRegionId = (region) => {
  return region?.split('-')?.reverse()?.[0] ?? ''
}

/** Check if URL has any changes in URL params, which casuses redirection
 * eg. '/cars-for-sale/nsw' => 'cars-for-sale/nsw/wa'
 */
export const URLFiltersUpdated = (router, filter) => {
  const currentFilter = router.query
  const { state, region, make, model } = currentFilter
  if (filter?.state !== state) {
    return true
  }

  if (filter?.region !== region) {
    return true
  }

  if (filter?.make !== make) {
    return true
  }

  if (filter?.model !== model) {
    return true
  }
  return false
}

/** Updates slug array for pagination  */
export const updatePaginationSlugs = (defaultTitle, router) => {
  const title = defaultTitle ?? 'cars-for-sale/search/'
  const { state, region, make, model } = router?.query ?? {}

  return [title, state, region, make, model]?.filter((item) => item)
}

/**
 * Function to check filter value.
 *
 * @param {String} value Filter value.
 *
 * @returns {Bool}
 */
export const isFilterActive = (value) => {
  if (!value || value === 'all') {
    return false
  }

  return true
}

/**
 * Update car listing image object.
 *
 * @param {Object} imageObject Schema Image Object
 * @param {Object} carData     Car details to add in schema.
 *
 * @returns {Object}
 */
export const updateImageObjectForCarListing = (imageObject, carData) => {
  imageObject['@id'] = `${carData?.uri}#primaryimage`
  imageObject.url = carData?.imageUrl

  return imageObject
}

/**
 * Update car listing webpage object.
 *
 * @param {Object} webpageObject Schema Webpage Object
 * @param {Object} carData       Car details to add in schema.
 *
 * @returns {Object}
 */
export const updateWebPageObjectForCarListing = (webpageObject, carData) => {
  webpageObject['@id'] = `${carData?.uri}#webpage`
  webpageObject.url = carData?.uri
  webpageObject.name = carData?.name

  if (carData?.publishAt) {
    webpageObject.datePublished = carData?.publishAt
    webpageObject.dateModified = carData?.publishAt // Need to update this later when we will have modified date.
  }

  webpageObject.breadcrumb = { '@id': `${carData?.uri}#breadcrumb` }
  webpageObject.potentialAction = [
    {
      target: [carData?.uri]
    }
  ]

  if (webpageObject?.primaryImageOfPage) {
    webpageObject.primaryImageOfPage = { '@id': `${carData?.uri}#primaryimage` }
  }

  return webpageObject
}

/**
 * Merge States and available states. Sets disabled for unavailable states
 * @param {Arrray} states All States
 * @param {Array} availableStates States with listing count
 * @returns {Array} States with disabled set
 */
export const mergeAvailableState = (states, availableStates, onMergeCallBack) => {
  if (!isArray(states) || !isArray(availableStates)) {
    return []
  }

  /** Reset state listing count  */
  const statesWithoutCount = states.map((state) => {
    return { ...state, count: undefined }
  })
  /** Merge  states and there listing count. */

  const mergedList = mergeObjectArray(statesWithoutCount, availableStates, 'slug')
  /** Disable states without variants */
  const updatedStateList = mergedList.map((state) => {
    const { slug } = state || {}
    const stateName = STATE_NAME_MAP?.[slug]?.name ?? ''
    const isDisabled = !(state?.count > 0)
    let mergedData = { ...state, disabled: isDisabled, title: stateName }
    if (onMergeCallBack) {
      const { data } = onMergeCallBack(mergedData) || {}
      if (data && !isEmpty(data)) {
        mergedData = { ...data }
      }
    }
    return mergedData
  })
  return updatedStateList
}

/**
 * Merge Regions and available regions. Sets disabled for unavailable regions
 * @param {Arrray} regions All Regions
 * @param {Array} availableRegions Regions with listing count
 * @returns {Array} Regions with disabled set
 */
export const mergeAvailableRegions = (regions, availableRegions, onMergeCallBack) => {
  if (!isArray(regions) || !isArray(availableRegions)) {
    return []
  }
  /** Reset region listing count  */
  const regionsWithoutCount = regions.map((region) => {
    return { ...region, count: undefined }
  })
  const updatedRegions = mergeObjectArray(regionsWithoutCount, availableRegions, 'slug').map(
    (region) => {
      const isDisabled = !(region?.count > 0)
      let mergedData = { ...region, disabled: isDisabled }
      if (onMergeCallBack) {
        const { data } = onMergeCallBack(mergedData) || {}
        if (data && !isEmpty(data)) {
          mergedData = { ...data }
        }
      }
      return mergedData
    }
  )
  return updatedRegions
}

/**
 * Merge Makes and available makes. Sets disabled for unavailable makes
 * @param {Arrray} makes All makes
 * @param {Array} availableMakes Makes with listing count
 * @returns {Array} Makes with disabled set
 */
export const mergeAvailableMakes = (makes, availableMakes, onMergeCallBack) => {
  if (!isArray(makes) || !isArray(availableMakes)) {
    return []
  }
  /** Reset makes listing count  */
  const makesWithoutCount = makes.map((make) => {
    return { ...make, count: undefined }
  })

  const updatedMakes = mergeObjectArray(makesWithoutCount, availableMakes, 'slug').map((make) => {
    const isDisabled = !(make?.count > 0)
    let mergedData = { ...make, disabled: isDisabled }
    if (onMergeCallBack) {
      const { data } = onMergeCallBack(mergedData) || {}
      if (data && !isEmpty(data)) {
        mergedData = { ...data }
      }
    }
    return mergedData
  })
  return updatedMakes
}

const VfactsSegmentMapping = {
  Medium: 'med',
  'Upper Large': 'uplarge',
  'PU/CC 4x4': 'utes4wd',
  'People Movers': 'people',
  'Vans/CC': 'vanscc',
  'Trucks 3,501-7,500 kgs GVM': '',
  'LD 3,501-8,000 kgs GVM': '',
  'PU/CC 4X2': 'utes2wd',
  'Light Buses': 'lightbus'
}

const VfactsClassMapping = {
  Passenger: 'car',
  SUV: 'suv',
  'Light Commercial': '',
  'Heavy Commercial': ''
}

export const getPriceBracket = (price) => {
  if (25000 > price) {
    return 'under25'
  } else if (40000 > price) {
    return '25to39'
  } else if (60000 > price) {
    return '40to59'
  } else if (70000 > price) {
    return '60to69'
  } else if (100000 > price) {
    return '70to100'
  } else if (100000 < price) {
    return 'over100'
  }
}

const FuelTypeMapping = {
  Electric_Electric: 'Electric',
  Piston_Diesel: 'Diesel',
  Piston_Petrol: 'Petrol',
  'Piston_Petrol - Premium ULP': 'Petrol',
  'Piston_Petrol - Unleaded ULP': 'Petrol',
  'Piston_Petrol or LPG (Dual)': 'Petrol',
  'Piston_Petrol or CNG (Dual)': 'Petrol',
  'Piston - Electric_Petrol': 'Hybrid',
  'Piston - Electric_Petrol - Premium ULP': 'Hybrid',
  'Piston - Electric_Petrol - Unleaded ULP': 'Hybrid'
}

export const getFuelType = (fuelType, engineTypeDescription) => {
  return FuelTypeMapping[`${engineTypeDescription}_${fuelType}`]
}

/**
 * Function to update the dataLayer and targeting for CFS landing page.
 *
 * @param {Object} dataLayerTargeting DataLayer and targeting.
 * @param {Object} filters            Selected filters.
 *
 * @returns {Object}
 */
export const updateCFSPageTargetingAndDataLayer = (dataLayerTargeting, filters) => {
  const { dataLayer, targeting } = dataLayerTargeting || {}
  const {
    make,
    model,
    year,
    fuelType,
    vehicleVfactsPrice,
    vehicleVfactsClass,
    vehicleVfactsSegment,
    price,
    engineTypeDescription,
    isNused,
    createdAt,
    updatedAt,
    pageTitle,
    author,
    stockId
  } = filters || {}

  // avoid mutation
  const dataLayerData = cloneDeep(dataLayer ?? [])
  const targetingData = cloneDeep(targeting ?? {})

  if (stockId) {
    dataLayerData[0].article.postId = stockId
  }
  if (author) {
    dataLayerData[0].article.author = author
  }
  if (createdAt) {
    dataLayerData[0].article.postDate = createdAt
  }
  if (updatedAt) {
    dataLayerData[0].article.modDate = updatedAt
  }
  if (pageTitle) {
    dataLayerData[0].article.title = pageTitle
  }
  if (isNused === 0 || isNused === 1) {
    dataLayerData[0].isNused = isNused
  }
  if (fuelType && engineTypeDescription) {
    const ft = getFuelType(fuelType, engineTypeDescription)
    dataLayerData[0].fueltype = [ft]
    targetingData.FT.push(ft)
  }

  if (year) {
    dataLayerData[0].year = [year]
    targetingData.YR.push(year)
  }

  if (price) {
    const pb = getPriceBracket(price)
    if (pb) {
      dataLayerData[0].pricebracket = [pb]
      targetingData.PB.push(pb)
    }
  }

  if (vehicleVfactsPrice && vehicleVfactsClass && vehicleVfactsSegment) {
    const vfactsClass = VfactsClassMapping[vehicleVfactsClass] ?? vehicleVfactsClass
    const vfactsSegment = VfactsSegmentMapping[vehicleVfactsSegment] ?? vehicleVfactsSegment
    const vfactsPrice = vehicleVfactsPrice
      .replace(/\$(\d+)K/g, '$1')
      .replace(/\>/, 'over')
      .replace(/\<\=?/, 'under')
      .replace(/\s/g, '')
    let classSegment = `${vfactsClass}${vfactsSegment}${vfactsPrice}`.toLowerCase()
    dataLayerData[0].vfactssegment = classSegment
    targetingData.VSG.push(classSegment)
  }

  if (!isEmpty(make) && !isEmpty(make?.name)) {
    dataLayerData[0].make.push(make.name?.toUpperCase())
    targetingData.MK.push(make.name?.toUpperCase())
  }

  if (!isEmpty(model) && !isEmpty(model?.name)) {
    dataLayerData[0].model.push(model.name?.toUpperCase())
    targetingData.MD.push(model.name?.toUpperCase())
  }

  return {
    dataLayer: JSON.stringify(dataLayerData),
    targeting: JSON.stringify(targetingData)
  }
}

/**
 * Parse seo title for cars for sale archive page
 * @param {String} make
 * @param {String} model
 * @param {String} region
 * @param {String} state
 * @returns {String} Parsed SEO title
 */
export const parseSeoTitle = (config) => {
  const {
    listingTypes = [],
    state: rawState = '',
    region: rawRegion = '',
    make: rawMake = '',
    model: rawModel = '',
    priceRange = [],
    fuelTypes = [],
    bodyTypes = []
  } = config ?? {}
  const state = rawState && rawState !== 'all' ? startCase(rawState) : ''
  const updatedRegion = startCase(rawRegion)
  const region = rawRegion && rawRegion !== 'all' ? startCase(updatedRegion) : ''
  const make = rawMake
  const model = rawModel

  let title = []

  const hasAnyFilter =
    !isEmpty(make) ||
    !isEmpty(model) ||
    !isEmpty(state) ||
    !isEmpty(region) ||
    !isEmpty(priceRange) ||
    !isEmpty(fuelTypes) ||
    !isEmpty(bodyTypes)
  let listingType = getListingTypeHeading(listingTypes, hasAnyFilter)

  // Add Listing Type
  if (hasAnyFilter && listingType === 'All') {
    listingType = ''
  }
  title.push(listingType)

  let makeModelLabel = ``
  if (make) {
    makeModelLabel = `${make}`
    if (model) {
      makeModelLabel = `${makeModelLabel} ${model}`
    }
  }
  title.push(makeModelLabel)

  let fuelTypeLabel = ''
  if (!isEmpty(fuelTypes)) {
    if (fuelTypeLabel.length === 1) {
      fuelTypeLabel = `${fuelTypes[0].label} `
    } else {
      fuelTypeLabel = `${fuelTypes.map((f) => f.label).join(', ')}`
    }
  }

  title.push(fuelTypeLabel)

  let bodyTypeLabel = ''
  if (!isEmpty(bodyTypes)) {
    if (bodyTypeLabel.length === 1) {
      bodyTypeLabel = `${bodyTypes[0].label} `
    } else {
      bodyTypeLabel = `${bodyTypes.map((f) => f.label).join(', ')}`
    }
  }

  title.push(bodyTypeLabel)
  title.push('Cars for Sale')

  let priceLabel = ''
  if (priceRange && priceRange.length > 0) {
    const min = priceRange?.[0] ? formatPrice(priceRange?.[0], true) : null
    const max = priceRange?.[1] ? formatPrice(priceRange?.[1], true) : null
    priceLabel = getPriceHeading(min, max)
  }
  title.push(priceLabel)
  let locationLabel = ''
  if (state) {
    if (region) {
      locationLabel = `${region}, `
    }
    locationLabel = `in ${locationLabel}${state}`
  }
  title.push(locationLabel)
  if (!hasAnyFilter && isEmpty(listingTypes)) {
    return "Buy Cars on Australia's #1 Independent Car Sales Marketplace"
  }
  return title.filter((t) => t.length > 0).join(' ')
}

/**
 * Parse meta desc for cars for sale archive page
 * @param {String} make
 * @param {String} model
 * @param {String} region
 * @param {String} state
 * @param {String} resultCount
 * @returns {String} Parsed meta desc
 */
export const parseMetaDesc = (config) => {
  const {
    count = 0,
    listingTypes = [],
    state: rawState = '',
    region: rawRegion = '',
    make: rawMake = '',
    model: rawModel = '',
    priceRange = [],
    fuelTypes = [],
    bodyTypes = []
  } = config ?? {}
  const state = rawState && rawState !== 'all' ? startCase(rawState) : ''
  const updatedRegion = startCase(rawRegion)
  const region = rawRegion && rawRegion !== 'all' ? startCase(updatedRegion) : ''
  const make = rawMake
  const model = rawModel
  const hasAnyFilter =
    !isEmpty(make) ||
    !isEmpty(model) ||
    !isEmpty(state) ||
    !isEmpty(region) ||
    !isEmpty(priceRange) ||
    !isEmpty(fuelTypes) ||
    !isEmpty(bodyTypes)
  let title = []
  let listingType = getListingTypeHeading(listingTypes)

  // Has any filter
  if (hasAnyFilter) {
    if (count > 0) {
      title.push(`${priceFormat(count)}`)
    }
    if (listingType !== 'All') {
      title.push(listingType)
    }
    let makeModelLabel = ``
    if (make) {
      makeModelLabel = `${make}`
      if (model) {
        makeModelLabel = `${makeModelLabel} ${model}`
      }
    }
    title.push(makeModelLabel)
    let fuelTypeLabel = ''
    if (!isEmpty(fuelTypes)) {
      if (fuelTypeLabel.length === 1) {
        fuelTypeLabel = `${fuelTypes[0].label} `
      } else {
        fuelTypeLabel = `${fuelTypes.map((f) => f.label).join(', ')}`
      }
    }
    title.push(fuelTypeLabel)

    let bodyTypeLabel = ''
    if (!isEmpty(bodyTypes)) {
      if (bodyTypeLabel.length === 1) {
        bodyTypeLabel = `${bodyTypes[0].label} `
      } else {
        bodyTypeLabel = `${bodyTypes.map((f) => f.label).join(', ')}`
      }
    }
    title.push(bodyTypeLabel)
    title.push(getCFSDefaultLabel(count))

    let priceLabel = ''
    if (priceRange && priceRange.length > 0) {
      const min = priceRange?.[0] ? formatPrice(priceRange?.[0], true) : null
      const max = priceRange?.[1] ? formatPrice(priceRange?.[1], true) : null
      priceLabel = getPriceHeading(min, max)
    }
    title.push(priceLabel)
    title.push('from vetted, handpicked dealers')

    let locationLabel = ''
    if (state) {
      if (region) {
        locationLabel = `${region}, `
      }
      locationLabel = `in ${locationLabel}${state}`
    }
    title.push(locationLabel)
    title.push('-')
    title.push('find the best')
    title.push(makeModelLabel)
    title.push('deals and buy with confidence.')
  }

  if (!hasAnyFilter) {
    if (listingType === 'All') {
      title.push(
        "Drive Marketplace is Australia's leading car sales platform offering new, demo and used cars from vetted, handpicked dealers. Start your next car search today!"
      )
    } else {
      title.push("Drive Marketplace is Australia's leading car sales platform offering")
      title.push(listingType)
      title.push('Cars For Sale from vetted, handpicked dealers. Start your next car search today!')
    }
  }

  title = title.filter((t) => t.length > 0)
  if (isEmpty(title)) {
    return "Drive Marketplace is Australia's leading car sales platform offering new, demo and used cars from vetted, handpicked dealers. Start your next car search today!"
  } else {
    return title.join(' ')
  }
}

/**
 * Parse headline for cars for sale archive page
 * @param {String} make
 * @param {String} model
 * @param {String} region
 * @param {String} state
 * @param {Array} selectedListingTypes
 * @param {String} pageTitle
 * @returns {String} Parsed headline
 */
export const parseHeadline = (
  parsedMake,
  parsedModel,
  parsedRegion,
  parsedState,
  selectedListingTypes,
  pageTitle
) => {
  const make = parsedMake ? `${parsedMake} ` : ''
  const model = parsedModel ? `${parsedModel} ` : ''
  const region = parsedRegion ? `${parsedRegion}, ` : ''
  const state = parsedState ? `${parsedState}` : ''
  let listingType = ''

  if (selectedListingTypes) {
    if (selectedListingTypes.includes('used')) {
      listingType = 'Used '
    }
    if (selectedListingTypes.includes('new')) {
      listingType = 'New '
    }
    if (
      selectedListingTypes.includes('new') &&
      selectedListingTypes.includes('used') &&
      selectedListingTypes.includes('demo')
    ) {
      listingType = ''
    }
  }

  if (make === '' && model === '' && region === '' && state === '') {
    return pageTitle
  }

  if (region !== '' || state !== '') {
    return `${listingType}${make}${model}Cars for Sale in ${region}${state}`
  }

  return `${listingType}${make}${model}Cars for Sale`
}

/**
 * Parse seo meta for cars for sale for search page
 * @returns {Object} Parsed SEO meta data - title, meta desc, image data, canonical and headline
 */
export const getParsedSeoMetaAndHeadline = ({
  listingType,
  state,
  region,
  make,
  model,
  pageNo,
  makeNameFromCFS = '',
  modelNameFromCFS = '',
  resultCount = 0,
  preloadImages = [],
  pageTitle = '',
  seo = {},
  options = {
    includeCustomImageMetaKey: true
  },
  searchFacade = '',
  optionRecord,
  listingInfo,
  filters
}) => {
  const parsedState = startCase(STATE_NAME_MAP?.[state]?.name || '') ?? ''
  const parsedRegion = startCase(region)

  // defaults
  let title = seo?.title
  let metaDesc = seo?.metaDesc

  const canonicalUriRelative = isEmpty(searchFacade)
    ? generateCFSUrl({
        filters: {
          ...DEFAULT_CFS_FILTERS,
          listingType,
          state,
          region,
          make,
          model
        },
        pageNo
      }).url
    : generateCFSSearchFacadeUrl(searchFacade, pageNo)
  const canonicalUri = `${getRuntimeEnv('NEXT_PUBLIC_FRONTEND_URL')}${canonicalUriRelative}`

  let pageName = CARS_FOR_SALE_ARCHIVES_PAGE_WITHOUT_PIPE
  const headline = parseHeadline(
    makeNameFromCFS,
    modelNameFromCFS,
    parsedRegion,
    parsedState,
    listingType,
    pageTitle
  )

  // Generate custom title for page
  title = parseSeoTitle({
    count: listingInfo?.itemCount,
    listingTypes: filters?.listingType ?? [],
    state: optionRecord?.state?.selected?.label ?? '',
    region: optionRecord?.region?.selected?.label ?? '',
    make: optionRecord?.make?.selected?.label ?? '',
    model: optionRecord?.model?.selected?.label ?? '',
    priceRange: filters?.price ?? [],
    fuelTypes: optionRecord?.fuelType?.selected ?? [],
    bodyTypes: optionRecord?.bodyType?.selected ?? []
  })
  // Generate custom description for page
  metaDesc = parseMetaDesc({
    count: listingInfo?.itemCount,
    listingTypes: filters?.listingType ?? [],
    state: optionRecord?.state?.selected?.label ?? '',
    region: optionRecord?.region?.selected?.label ?? '',
    make: optionRecord?.make?.selected?.label ?? '',
    model: optionRecord?.model?.selected?.label ?? '',
    priceRange: filters?.price ?? [],
    fuelTypes: optionRecord?.fuelType?.selected ?? [],
    bodyTypes: optionRecord?.bodyType?.selected ?? []
  })

  pageName = CARS_FOR_SALE_ARCHIVES_PAGE

  const parsedImageData = preloadImages.map((imgItem) => {
    return getImageData(
      imgItem?.srcUrl ?? '',
      {
        publicId: imgItem?.publicId ?? '',
        cloudName: imgItem?.cloudName ?? '',
        deliveryType: imgItem?.deliveryType ?? ''
      },
      CARS_FOR_SALE_LISTINGS_CARD
    )
  })

  const parsedRetData = {
    customTitle: title ?? '',
    customMetaDesc: metaDesc ?? '',
    canonicalUri,
    preloadImageData: parsedImageData ?? {},
    headline,
    pageName
  }

  if (options.includeCustomImageMetaKey) {
    parsedRetData.customImageMeta = parsedImageData?.customImageMeta ?? {}
  }

  return parsedRetData
}
